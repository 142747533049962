<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.stages') }} :: {{ model.name }}
        </h3>

        <router-link :to="{ name: 'events-stages' }">
          <b-button size="sm" class="ml-3" variant="info">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-backspace-fill mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241
                7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2
                2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147
                2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0
                1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976
                8 5.829 5.854z"
              />
            </svg>

            {{ $t('buttons.back') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <form @submit.prevent="onSubmit" v-if="loaded">
      <b-row>
        <b-col cols="2" class="mb-3">
          <b-form-checkbox v-model="model.isLive" name="check-button" switch>
            {{ $t('stage.live') }}
          </b-form-checkbox>
        </b-col>
        <b-col cols="2" class="mb-3">
          <b-form-checkbox v-model="model.isBackup" name="check-button" switch>
            Backup
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" md="6" sm="12">
          <h6 class="mt-4 mb-3"><strong>Embeds PT</strong></h6>

          <b-input-group prepend="Live">
            <b-form-input v-model="model.embed" maxlength="250" type="text" />
            <b-input-group-append is-text v-b-tooltip.hover.html="getTooltip()">
              <b-icon icon="exclamation-circle" />
            </b-input-group-append>
          </b-input-group>

          <small v-html="getTooltip()" class="mb-3 d-block text-right" />

          <b-input-group prepend="Chat">
            <b-form-input
              v-model="model.embedChat"
              maxlength="250"
              type="text"
            />
            <b-input-group-append is-text v-b-tooltip.hover.html="getTooltip()">
              <b-icon icon="exclamation-circle" />
            </b-input-group-append>
          </b-input-group>

          <small v-html="getTooltip()" class="mb-3 d-block text-right" />

          <b-input-group prepend="Backup (src)">
            <b-form-input
              :placeholder="$t('placeholder.embedBackup')"
              v-model="model.embedBackup"
              maxlength="500"
              type="url"
            />
          </b-input-group>
        </b-col>

        <b-col cols="6" md="6" sm="12">
          <h6 class="mt-4 mb-3"><strong>Embeds EN</strong></h6>

          <b-input-group prepend="Live">
            <b-form-input
              v-model="model.embedSecondary"
              maxlength="250"
              type="text"
            />
            <b-input-group-append is-text v-b-tooltip.hover.html="getTooltip()">
              <b-icon icon="exclamation-circle" />
            </b-input-group-append>
          </b-input-group>

          <small v-html="getTooltip()" class="mb-3 d-block text-right" />

          <b-input-group prepend="Chat">
            <b-form-input
              v-model="model.embedSecondaryChat"
              maxlength="250"
              type="text"
            />
            <b-input-group-append is-text v-b-tooltip.hover.html="getTooltip()">
              <b-icon icon="exclamation-circle" />
            </b-input-group-append>
          </b-input-group>

          <small v-html="getTooltip()" class="mb-3 d-block text-right" />

          <b-input-group prepend="Backup (src)">
            <b-form-input
              v-model="model.embedSecondaryBackup"
              :placeholder="$t('placeholder.embedBackup')"
              maxlength="500"
              type="url"
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-col class="border-top border-bottom pl-0 my-5" />

      <b-row>
        <b-col lg="12" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.name')">
            <b-form-input
              v-model="model.name"
              maxlength="200"
              type="text"
              required
              disabled
            />
          </b-input-group>
        </b-col>

        <b-col lg="12" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.fanCamLink')">
            <b-form-input
              v-model="model.fancamURL"
              maxlength="100"
              type="url"
            />
          </b-input-group>
        </b-col>

        <b-col lg="12" sm="12" class="mb-3">
          <b-input-group prepend="Flowics (visualization)">
            <b-form-input
              v-model="model.flowics"
              maxlength="1000"
              type="text"
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-col class="border-top border-bottom pl-0 my-5" />

      <b-row class="mb-4">
        <b-col lg="12" sm="12">
          <h6>
            <strong>Configurações sem transmissão ao vivo</strong>
          </h6>
        </b-col>

        <b-col cols="6" md="6" sm="12">
          <h6 class="mt-4 mb-3">
            <strong>Textos PT</strong>
          </h6>

          <b-input-group :prepend="$t('inputs.titlePT')" class="mb-3">
            <b-form-input
                v-model="model.offlineTitlePT"
                maxlength="200"
                type="text"
            />
          </b-input-group>

          <b-input-group :prepend="$t('inputs.descriptionPT')" class="mb-3">
            <b-form-input
                v-model="model.offlineDescriptionPT"
                maxlength="200"
                type="text"
            />
          </b-input-group>
        </b-col>

        <b-col cols="6" md="6" sm="12">
          <h6 class="mt-4 mb-3">
            <strong>Textos EN</strong>
          </h6>

          <b-input-group :prepend="$t('inputs.titleEN')" class="mb-3">
            <b-form-input
                v-model="model.offlineTitleEN"
                maxlength="200"
                type="text"
            />
          </b-input-group>

          <b-input-group :prepend="$t('inputs.descriptionEN')" class="mb-3">
            <b-form-input
                v-model="model.offlineDescriptionEN"
                maxlength="200"
                type="text"
            />
          </b-input-group>
        </b-col>

        <b-col cols="12" md="12" sm="12" class="mb-3">
          <b-input-group prepend="Palco em destaque">
            <b-form-select v-model="model.offlineStageId" :options="stageOptions"></b-form-select>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            class="module mr-3"
            variant="outline-primary"
            @click="
              $router.push({
                name: `events-stages-videos`,
                params: { id: model.id }
              })
            "
          >
            <b-icon icon="camera-video" />
            {{ $t('artistsManagement.videos') }}
            <b-badge variant="secondary">{{ model.videos.length }}</b-badge>
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" sm="12" class="mb-3 text-right">
          <b-button :disabled="loading" type="submit" variant="success">
            {{ $t('buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </form>

    <b-row v-else>
      <b-col cols="12">
        <h5 class="text-center mt-4">{{ $t('messages.loading') }}...</h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import EventsStage from '../../../../services/EventsStage';
import Toast from '../../../../assets/js/toast';
// import ImageUploader from '../../../../components/organisms/ImageUploader.vue';

export default {
  // components: {
  //   ImageUploader,
  // },

  data() {
    const data = {
      stageOptions: [],

      isEdit: false,
      loaded: false,
      loading: true,
      deleteModal: false,

      model: {
        name: '',

        embed: '',
        embedChat: '',
        embedBackup: '',
        embedSecondary: '',
        embedSecondaryChat: '',
        embedSecondaryBackup: '',

        fancamURL: '',
        flowics: '',
        headerImage: '',
        isLive: false,

        offlineTitlePT: '',
        offlineDescriptionPT: '',
        offlineTitleEN: '',
        offlineDescriptionEN: '',
        offlineStageId: null,
      },

      upload: {
        file: null,
        preview: null,
        blob: null,
      },

      originalImage: '',
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      const { id } = this.$route.params;
      this.loading = true;
      const listStages = await EventsStage.get();
      this.model = await EventsStage.getById(id);

      this.stageOptions = listStages.map((stage) => ({
        value: stage.id,
        text: `${stage.name} (${stage.isLive ? 'Ao vivo' : 'Offline'})`,
        disabled: `${stage.id}` === `${id}`,
      }));

      this.stageOptions.unshift({
        value: null,
        text: 'Nenhum palco',
      });

      if (this.model.headerImage) {
        this.upload.preview = `${Vue.prototype.VUE_APP_S3_URL}/stage/${this.model.id}/bg/${this.model.headerImage}`;
      }

      this.loading = false;
      this.loaded = true;
    },

    async onSubmit() {
      try {
        this.loading = true;

        if (this.upload.file) {
          this.model.headerImage = await this.uploadImage();
        }

        await EventsStage.update(this.model, this.model.id);

        Toast.success(this, 'messages.saveSuccess');

        setTimeout(() => {
          this.$router.push({ name: 'events-stages' });
          this.loading = false;
        }, 1000);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
        this.loading = false;
      }
    },

    async uploadImage() {
      if (!this.upload.file) {
        return this.model.headerImage;
      }

      const fd = new FormData();
      fd.append('file', this.upload.blob, this.upload.file.name);

      const upload = await EventsStage.upload(fd, this.model.id);

      if (!upload.filename) {
        Sentry.withScope((scope) => {
          scope.setExtra(
            'errorStageUpload',
            JSON.stringify(this.model, undefined, 2),
          );
          Sentry.captureException(new Error('error upload stage image'));
        });
      }

      return upload.filename || this.model.headerImage;
    },
    getTooltip() {
      return '<small>https://vimeo.com/</small><strong>76979871</strong>';
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
