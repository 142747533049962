import Vue from 'vue';

export default {
  get: async () => Vue.http.get('events/stage')
    .then((res) => res.json()),

  getById: async (id) => Vue.http.get(`events/stage/${id}`)
    .then((res) => res.json()),

  upload: async (model, id) => Vue.http.post(`events/stage/${id}/upload/bg`, model)
    .then((res) => res.json()),

  update: async (model, id) => Vue.http.put(`events/stage/${id}`, model)
    .then((res) => res.json()),

  getVideos: async (id) => Vue.http.get(`events/stage/${id}/videos`)
    .then((res) => res.json()),

  insertVideo: async (model, id) => Vue.http.post(`events/stage/${id}/videos/`, model)
    .then((res) => res.json()),

  updateVideo: async (model, id) => Vue.http.put(`events/stage/${id}/videos/${model.id}`, model)
    .then((res) => res.json()),

  deleteVideo: async (videoId, id) => Vue.http.delete(`events/stage/${id}/videos/${videoId}/`)
    .then((res) => res.json()),

  reorderVideo: async (model, id) => Vue.http.post(`events/stage/${id}/videos/reorder/`, model)
    .then((res) => res.json()),

  uploadPicture: async (model, id, type) => Vue.http.post(`events/stage/${id}/upload/${type}/`, model)
    .then((res) => res.json()),
};
